
// @ts-nocheck
import { defineComponent } from 'vue'
import { useBreadcrumbsStore } from '~/store/breadcrumbs'
import { getBreadCrumbs } from '~/util/routeFunctions'
const breadcrumbsStore = useBreadcrumbsStore()
export default defineComponent({
  data () {
    return {
      pathArr: [] as ReturnType<typeof getBreadCrumbs>

    }
  },

  computed: {
    titles () {
      return breadcrumbsStore.value.titles
    },
    route () {
      return this.$route
    }
  },
  watch: {
    titles (value) {
      this.pathArr = getBreadCrumbs.call(this, this.$route.path, JSON.parse(JSON.stringify(value)))
    },
    route (value, old) {
      if (value.path === old.path) { return }

      this.pathArr = getBreadCrumbs.call(this, this.$route.path, value)
    }

  },
  created () {
    this.pathArr = getBreadCrumbs.call(this, this.$route.path, JSON.parse(JSON.stringify(breadcrumbsStore?.value?.titles)))
  }
})

