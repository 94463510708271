export const AppTopItem = () => import('../../components/AppTopItem.vue' /* webpackChunkName: "components/app-top-item" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const FullScreen = () => import('../../components/FullScreen.vue' /* webpackChunkName: "components/full-screen" */).then(c => wrapFunctional(c.default || c))
export const MerchNotify = () => import('../../components/MerchNotify.vue' /* webpackChunkName: "components/merch-notify" */).then(c => wrapFunctional(c.default || c))
export const MerchTopItem = () => import('../../components/MerchTopItem.vue' /* webpackChunkName: "components/merch-top-item" */).then(c => wrapFunctional(c.default || c))
export const PaintingList = () => import('../../components/PaintingList.vue' /* webpackChunkName: "components/painting-list" */).then(c => wrapFunctional(c.default || c))
export const AmpAppTopItem = () => import('../../components/amp/AmpAppTopItem.vue' /* webpackChunkName: "components/amp-app-top-item" */).then(c => wrapFunctional(c.default || c))
export const ArtistGallery = () => import('../../components/artist/Gallery.vue' /* webpackChunkName: "components/artist-gallery" */).then(c => wrapFunctional(c.default || c))
export const ArtistManifest = () => import('../../components/artist/Manifest.vue' /* webpackChunkName: "components/artist-manifest" */).then(c => wrapFunctional(c.default || c))
export const ArtistToggleButton = () => import('../../components/artist/ToggleButton.vue' /* webpackChunkName: "components/artist-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const BuyPainting = () => import('../../components/buy/BuyPainting.vue' /* webpackChunkName: "components/buy-painting" */).then(c => wrapFunctional(c.default || c))
export const BuyCompletionBuy = () => import('../../components/buy/CompletionBuy.vue' /* webpackChunkName: "components/buy-completion-buy" */).then(c => wrapFunctional(c.default || c))
export const BuyMakingPurchase = () => import('../../components/buy/MakingPurchase.vue' /* webpackChunkName: "components/buy-making-purchase" */).then(c => wrapFunctional(c.default || c))
export const ArtistList = () => import('../../components/artist-gallery/ArtistList.vue' /* webpackChunkName: "components/artist-list" */).then(c => wrapFunctional(c.default || c))
export const ArtistGalleryInputSearch = () => import('../../components/artist-gallery/InputSearch.vue' /* webpackChunkName: "components/artist-gallery-input-search" */).then(c => wrapFunctional(c.default || c))
export const BaseBtn = () => import('../../components/base/BaseBtn.vue' /* webpackChunkName: "components/base-btn" */).then(c => wrapFunctional(c.default || c))
export const BasePopup = () => import('../../components/base/BasePopup.vue' /* webpackChunkName: "components/base-popup" */).then(c => wrapFunctional(c.default || c))
export const BaseSpinner = () => import('../../components/base/BaseSpinner.vue' /* webpackChunkName: "components/base-spinner" */).then(c => wrapFunctional(c.default || c))
export const DadataInput = () => import('../../components/dadata/dadataInput.vue' /* webpackChunkName: "components/dadata-input" */).then(c => wrapFunctional(c.default || c))
export const FormsFeedbackForm = () => import('../../components/forms/FeedbackForm.vue' /* webpackChunkName: "components/forms-feedback-form" */).then(c => wrapFunctional(c.default || c))
export const IconsAddPlus = () => import('../../components/icons/AddPlus.vue' /* webpackChunkName: "components/icons-add-plus" */).then(c => wrapFunctional(c.default || c))
export const IconsApply = () => import('../../components/icons/Apply.vue' /* webpackChunkName: "components/icons-apply" */).then(c => wrapFunctional(c.default || c))
export const IconsArrow = () => import('../../components/icons/Arrow.vue' /* webpackChunkName: "components/icons-arrow" */).then(c => wrapFunctional(c.default || c))
export const IconsBaseIcon = () => import('../../components/icons/BaseIcon.vue' /* webpackChunkName: "components/icons-base-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCalendar = () => import('../../components/icons/Calendar.vue' /* webpackChunkName: "components/icons-calendar" */).then(c => wrapFunctional(c.default || c))
export const IconsCamera = () => import('../../components/icons/Camera.vue' /* webpackChunkName: "components/icons-camera" */).then(c => wrapFunctional(c.default || c))
export const IconsCar = () => import('../../components/icons/Car.vue' /* webpackChunkName: "components/icons-car" */).then(c => wrapFunctional(c.default || c))
export const IconsCart = () => import('../../components/icons/Cart.vue' /* webpackChunkName: "components/icons-cart" */).then(c => wrapFunctional(c.default || c))
export const IconsChart = () => import('../../components/icons/Chart.vue' /* webpackChunkName: "components/icons-chart" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckMark = () => import('../../components/icons/CheckMark.vue' /* webpackChunkName: "components/icons-check-mark" */).then(c => wrapFunctional(c.default || c))
export const IconsClipboard = () => import('../../components/icons/Clipboard.vue' /* webpackChunkName: "components/icons-clipboard" */).then(c => wrapFunctional(c.default || c))
export const IconsClock = () => import('../../components/icons/Clock.vue' /* webpackChunkName: "components/icons-clock" */).then(c => wrapFunctional(c.default || c))
export const IconsCross = () => import('../../components/icons/Cross.vue' /* webpackChunkName: "components/icons-cross" */).then(c => wrapFunctional(c.default || c))
export const IconsEdit = () => import('../../components/icons/Edit.vue' /* webpackChunkName: "components/icons-edit" */).then(c => wrapFunctional(c.default || c))
export const IconsError = () => import('../../components/icons/Error.vue' /* webpackChunkName: "components/icons-error" */).then(c => wrapFunctional(c.default || c))
export const IconsExit = () => import('../../components/icons/Exit.vue' /* webpackChunkName: "components/icons-exit" */).then(c => wrapFunctional(c.default || c))
export const IconsFileContract = () => import('../../components/icons/FileContract.vue' /* webpackChunkName: "components/icons-file-contract" */).then(c => wrapFunctional(c.default || c))
export const IconsFilter = () => import('../../components/icons/Filter.vue' /* webpackChunkName: "components/icons-filter" */).then(c => wrapFunctional(c.default || c))
export const IconsGraduationCap = () => import('../../components/icons/GraduationCap.vue' /* webpackChunkName: "components/icons-graduation-cap" */).then(c => wrapFunctional(c.default || c))
export const IconsHint = () => import('../../components/icons/Hint.vue' /* webpackChunkName: "components/icons-hint" */).then(c => wrapFunctional(c.default || c))
export const IconsHouse = () => import('../../components/icons/House.vue' /* webpackChunkName: "components/icons-house" */).then(c => wrapFunctional(c.default || c))
export const Icons = () => import('../../components/icons/Icons.vue' /* webpackChunkName: "components/icons" */).then(c => wrapFunctional(c.default || c))
export const IconsImage = () => import('../../components/icons/Image.vue' /* webpackChunkName: "components/icons-image" */).then(c => wrapFunctional(c.default || c))
export const IconsImages = () => import('../../components/icons/Images.vue' /* webpackChunkName: "components/icons-images" */).then(c => wrapFunctional(c.default || c))
export const IconsLampOn = () => import('../../components/icons/LampOn.vue' /* webpackChunkName: "components/icons-lamp-on" */).then(c => wrapFunctional(c.default || c))
export const IconsLocation = () => import('../../components/icons/Location.vue' /* webpackChunkName: "components/icons-location" */).then(c => wrapFunctional(c.default || c))
export const IconsLock = () => import('../../components/icons/Lock.vue' /* webpackChunkName: "components/icons-lock" */).then(c => wrapFunctional(c.default || c))
export const IconsLogo = () => import('../../components/icons/Logo.vue' /* webpackChunkName: "components/icons-logo" */).then(c => wrapFunctional(c.default || c))
export const IconsMedalStar = () => import('../../components/icons/MedalStar.vue' /* webpackChunkName: "components/icons-medal-star" */).then(c => wrapFunctional(c.default || c))
export const IconsPenHollow = () => import('../../components/icons/PenHollow.vue' /* webpackChunkName: "components/icons-pen-hollow" */).then(c => wrapFunctional(c.default || c))
export const IconsPerson = () => import('../../components/icons/Person.vue' /* webpackChunkName: "components/icons-person" */).then(c => wrapFunctional(c.default || c))
export const IconsPersonRays = () => import('../../components/icons/PersonRays.vue' /* webpackChunkName: "components/icons-person-rays" */).then(c => wrapFunctional(c.default || c))
export const IconsPresent = () => import('../../components/icons/Present.vue' /* webpackChunkName: "components/icons-present" */).then(c => wrapFunctional(c.default || c))
export const IconsProfile = () => import('../../components/icons/Profile.vue' /* webpackChunkName: "components/icons-profile" */).then(c => wrapFunctional(c.default || c))
export const IconsRangeBtn = () => import('../../components/icons/RangeBtn.vue' /* webpackChunkName: "components/icons-range-btn" */).then(c => wrapFunctional(c.default || c))
export const IconsReceive = () => import('../../components/icons/Receive.vue' /* webpackChunkName: "components/icons-receive" */).then(c => wrapFunctional(c.default || c))
export const IconsSearch = () => import('../../components/icons/Search.vue' /* webpackChunkName: "components/icons-search" */).then(c => wrapFunctional(c.default || c))
export const IconsSend = () => import('../../components/icons/Send.vue' /* webpackChunkName: "components/icons-send" */).then(c => wrapFunctional(c.default || c))
export const IconsSize = () => import('../../components/icons/Size.vue' /* webpackChunkName: "components/icons-size" */).then(c => wrapFunctional(c.default || c))
export const IconsSort = () => import('../../components/icons/Sort.vue' /* webpackChunkName: "components/icons-sort" */).then(c => wrapFunctional(c.default || c))
export const IconsTeacher = () => import('../../components/icons/Teacher.vue' /* webpackChunkName: "components/icons-teacher" */).then(c => wrapFunctional(c.default || c))
export const IconsTelegram = () => import('../../components/icons/Telegram.vue' /* webpackChunkName: "components/icons-telegram" */).then(c => wrapFunctional(c.default || c))
export const IconsTrashcan = () => import('../../components/icons/Trashcan.vue' /* webpackChunkName: "components/icons-trashcan" */).then(c => wrapFunctional(c.default || c))
export const IconsTrashcanFilled = () => import('../../components/icons/TrashcanFilled.vue' /* webpackChunkName: "components/icons-trashcan-filled" */).then(c => wrapFunctional(c.default || c))
export const IconsVK = () => import('../../components/icons/VK.vue' /* webpackChunkName: "components/icons-v-k" */).then(c => wrapFunctional(c.default || c))
export const IconsWidth = () => import('../../components/icons/Width.vue' /* webpackChunkName: "components/icons-width" */).then(c => wrapFunctional(c.default || c))
export const IndexFullScreenImage = () => import('../../components/index/FullScreenImage.vue' /* webpackChunkName: "components/index-full-screen-image" */).then(c => wrapFunctional(c.default || c))
export const IndexPaintingRate = () => import('../../components/index/PaintingRate.vue' /* webpackChunkName: "components/index-painting-rate" */).then(c => wrapFunctional(c.default || c))
export const JoinForm = () => import('../../components/join/JoinForm.vue' /* webpackChunkName: "components/join-form" */).then(c => wrapFunctional(c.default || c))
export const JoinPreview = () => import('../../components/join/JoinPreview.vue' /* webpackChunkName: "components/join-preview" */).then(c => wrapFunctional(c.default || c))
export const JoinSuccess = () => import('../../components/join/JoinSuccess.vue' /* webpackChunkName: "components/join-success" */).then(c => wrapFunctional(c.default || c))
export const LayoutBreadcrumbs = () => import('../../components/layout/LayoutBreadcrumbs.vue' /* webpackChunkName: "components/layout-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/LayoutFooter.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutHeader = () => import('../../components/layout/LayoutHeader.vue' /* webpackChunkName: "components/layout-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutLang = () => import('../../components/layout/LayoutLang.vue' /* webpackChunkName: "components/layout-lang" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenu = () => import('../../components/layout/LayoutMenu.vue' /* webpackChunkName: "components/layout-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutMerch = () => import('../../components/layout/LayoutMerch.vue' /* webpackChunkName: "components/layout-merch" */).then(c => wrapFunctional(c.default || c))
export const LayoutSearch = () => import('../../components/layout/LayoutSearch.vue' /* webpackChunkName: "components/layout-search" */).then(c => wrapFunctional(c.default || c))
export const LayoutLogoFull = () => import('../../components/layout/LogoFull.vue' /* webpackChunkName: "components/layout-logo-full" */).then(c => wrapFunctional(c.default || c))
export const LayoutSocialLinks = () => import('../../components/layout/SocialLinks.vue' /* webpackChunkName: "components/layout-social-links" */).then(c => wrapFunctional(c.default || c))
export const MerchToggleButton = () => import('../../components/merch/MerchToggleButton.vue' /* webpackChunkName: "components/merch-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const NewsItem = () => import('../../components/news-list/NewsItem.vue' /* webpackChunkName: "components/news-item" */).then(c => wrapFunctional(c.default || c))
export const PopupsApplicationPopup = () => import('../../components/popups/ApplicationPopup.vue' /* webpackChunkName: "components/popups-application-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsIntroPopup = () => import('../../components/popups/IntroPopup.vue' /* webpackChunkName: "components/popups-intro-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsPromoCodePopup = () => import('../../components/popups/PromoCodePopup.vue' /* webpackChunkName: "components/popups-promo-code-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsSynergyPopup = () => import('../../components/popups/SynergyPopup.vue' /* webpackChunkName: "components/popups-synergy-popup" */).then(c => wrapFunctional(c.default || c))
export const PopupsVoteForPainting = () => import('../../components/popups/VoteForPainting.vue' /* webpackChunkName: "components/popups-vote-for-painting" */).then(c => wrapFunctional(c.default || c))
export const PopupsWebPushPopup = () => import('../../components/popups/WebPushPopup.vue' /* webpackChunkName: "components/popups-web-push-popup" */).then(c => wrapFunctional(c.default || c))
export const SaleInfo = () => import('../../components/sale/SaleInfo.vue' /* webpackChunkName: "components/sale-info" */).then(c => wrapFunctional(c.default || c))
export const AmpHeader = () => import('../../components/amp/layout/AmpHeader.vue' /* webpackChunkName: "components/amp-header" */).then(c => wrapFunctional(c.default || c))
export const AmpMenu = () => import('../../components/amp/layout/AmpMenu.vue' /* webpackChunkName: "components/amp-menu" */).then(c => wrapFunctional(c.default || c))
export const AmpNewsItem = () => import('../../components/amp/news/AmpNewsItem.vue' /* webpackChunkName: "components/amp-news-item" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuBanner = () => import('../../components/layout/menu/MenuBanner.vue' /* webpackChunkName: "components/layout-menu-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuContent = () => import('../../components/layout/menu/MenuContent.vue' /* webpackChunkName: "components/layout-menu-content" */).then(c => wrapFunctional(c.default || c))
export const LayoutMenuToggleLang = () => import('../../components/layout/menu/ToggleLang.vue' /* webpackChunkName: "components/layout-menu-toggle-lang" */).then(c => wrapFunctional(c.default || c))
export const NewAboutUsQuestions = () => import('../../components/new/about-us/Questions.vue' /* webpackChunkName: "components/new-about-us-questions" */).then(c => wrapFunctional(c.default || c))
export const NewArtistInfoDropdowns = () => import('../../components/new/artist/InfoDropdowns.vue' /* webpackChunkName: "components/new-artist-info-dropdowns" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksAddArtwork = () => import('../../components/new/blocks/AddArtwork.vue' /* webpackChunkName: "components/new-blocks-add-artwork" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksArtHallLogo = () => import('../../components/new/blocks/ArtHallLogo.vue' /* webpackChunkName: "components/new-blocks-art-hall-logo" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksArtistCard = () => import('../../components/new/blocks/ArtistCard.vue' /* webpackChunkName: "components/new-blocks-artist-card" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksArtworkImage = () => import('../../components/new/blocks/ArtworkImage.vue' /* webpackChunkName: "components/new-blocks-artwork-image" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksCatalogBase = () => import('../../components/new/blocks/CatalogBase.vue' /* webpackChunkName: "components/new-blocks-catalog-base" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksCustomFileInput = () => import('../../components/new/blocks/CustomFileInput.vue' /* webpackChunkName: "components/new-blocks-custom-file-input" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksDropdownBlock = () => import('../../components/new/blocks/DropdownBlock.vue' /* webpackChunkName: "components/new-blocks-dropdown-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksEditWithSearchBlock = () => import('../../components/new/blocks/EditWithSearchBlock.vue' /* webpackChunkName: "components/new-blocks-edit-with-search-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksEnterAccount = () => import('../../components/new/blocks/EnterAccount.vue' /* webpackChunkName: "components/new-blocks-enter-account" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksErrorBlock = () => import('../../components/new/blocks/ErrorBlock.vue' /* webpackChunkName: "components/new-blocks-error-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksFAQBlock = () => import('../../components/new/blocks/FAQBlock.vue' /* webpackChunkName: "components/new-blocks-f-a-q-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksFindImage = () => import('../../components/new/blocks/FindImage.vue' /* webpackChunkName: "components/new-blocks-find-image" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksHintBlock = () => import('../../components/new/blocks/HintBlock.vue' /* webpackChunkName: "components/new-blocks-hint-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksInfoBlock = () => import('../../components/new/blocks/InfoBlock.vue' /* webpackChunkName: "components/new-blocks-info-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksLinkWithArrow = () => import('../../components/new/blocks/LinkWithArrow.vue' /* webpackChunkName: "components/new-blocks-link-with-arrow" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksMultiRangeSlideBlock = () => import('../../components/new/blocks/MultiRangeSlideBlock.vue' /* webpackChunkName: "components/new-blocks-multi-range-slide-block" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksNewsCard = () => import('../../components/new/blocks/NewsCard.vue' /* webpackChunkName: "components/new-blocks-news-card" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksNewsCardFull = () => import('../../components/new/blocks/NewsCardFull.vue' /* webpackChunkName: "components/new-blocks-news-card-full" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksNotice = () => import('../../components/new/blocks/Notice.vue' /* webpackChunkName: "components/new-blocks-notice" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksPaintingCard = () => import('../../components/new/blocks/PaintingCard.vue' /* webpackChunkName: "components/new-blocks-painting-card" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksPaintingDetails = () => import('../../components/new/blocks/PaintingDetails.vue' /* webpackChunkName: "components/new-blocks-painting-details" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksPaintingOrderCard = () => import('../../components/new/blocks/PaintingOrderCard.vue' /* webpackChunkName: "components/new-blocks-painting-order-card" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksQuestionsBlock = () => import('../../components/new/blocks/QuestionsBlock.vue' /* webpackChunkName: "components/new-blocks-questions-block" */).then(c => wrapFunctional(c.default || c))
export const NewJoinArthallStepsBlock = () => import('../../components/new/join-arthall/StepsBlock.vue' /* webpackChunkName: "components/new-join-arthall-steps-block" */).then(c => wrapFunctional(c.default || c))
export const NewJoinArthallToCabinet = () => import('../../components/new/join-arthall/ToCabinet.vue' /* webpackChunkName: "components/new-join-arthall-to-cabinet" */).then(c => wrapFunctional(c.default || c))
export const NewFormsAddArtworkForm = () => import('../../components/new/forms/AddArtworkForm.vue' /* webpackChunkName: "components/new-forms-add-artwork-form" */).then(c => wrapFunctional(c.default || c))
export const NewFormsArtworkDelivery = () => import('../../components/new/forms/ArtworkDelivery.vue' /* webpackChunkName: "components/new-forms-artwork-delivery" */).then(c => wrapFunctional(c.default || c))
export const NewFormsBuyPaintingForm = () => import('../../components/new/forms/BuyPaintingForm.vue' /* webpackChunkName: "components/new-forms-buy-painting-form" */).then(c => wrapFunctional(c.default || c))
export const NewFormsEditConcept = () => import('../../components/new/forms/EditConcept.vue' /* webpackChunkName: "components/new-forms-edit-concept" */).then(c => wrapFunctional(c.default || c))
export const NewFormsEditPersonalInfo = () => import('../../components/new/forms/EditPersonalInfo.vue' /* webpackChunkName: "components/new-forms-edit-personal-info" */).then(c => wrapFunctional(c.default || c))
export const NewFormsLogin = () => import('../../components/new/forms/Login.vue' /* webpackChunkName: "components/new-forms-login" */).then(c => wrapFunctional(c.default || c))
export const NewMainPageContent = () => import('../../components/new/main-page/MainPageContent.vue' /* webpackChunkName: "components/new-main-page-content" */).then(c => wrapFunctional(c.default || c))
export const NewPaintings = () => import('../../components/new/main-page/NewPaintings.vue' /* webpackChunkName: "components/new-paintings" */).then(c => wrapFunctional(c.default || c))
export const NewMainPagePaintingsBlock = () => import('../../components/new/main-page/PaintingsBlock.vue' /* webpackChunkName: "components/new-main-page-paintings-block" */).then(c => wrapFunctional(c.default || c))
export const NewMainPagePaintingsColumn = () => import('../../components/new/main-page/PaintingsColumn.vue' /* webpackChunkName: "components/new-main-page-paintings-column" */).then(c => wrapFunctional(c.default || c))
export const NewModalsAddArtworkModal = () => import('../../components/new/modals/AddArtworkModal.vue' /* webpackChunkName: "components/new-modals-add-artwork-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsArtworkDeliveryModal = () => import('../../components/new/modals/ArtworkDeliveryModal.vue' /* webpackChunkName: "components/new-modals-artwork-delivery-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsBuyPaintingModal = () => import('../../components/new/modals/BuyPaintingModal.vue' /* webpackChunkName: "components/new-modals-buy-painting-modal" */).then(c => wrapFunctional(c.default || c))
export const NewPaintingsPaintingInfo = () => import('../../components/new/paintings/PaintingInfo.vue' /* webpackChunkName: "components/new-paintings-painting-info" */).then(c => wrapFunctional(c.default || c))
export const NewPaintingsZoomablePainting = () => import('../../components/new/paintings/ZoomablePainting.vue' /* webpackChunkName: "components/new-paintings-zoomable-painting" */).then(c => wrapFunctional(c.default || c))
export const NewUiBaseButton = () => import('../../components/new/ui/BaseButton.vue' /* webpackChunkName: "components/new-ui-base-button" */).then(c => wrapFunctional(c.default || c))
export const NewUiBaseDropdown = () => import('../../components/new/ui/BaseDropdown.vue' /* webpackChunkName: "components/new-ui-base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const NewUiBaseImage = () => import('../../components/new/ui/BaseImage.vue' /* webpackChunkName: "components/new-ui-base-image" */).then(c => wrapFunctional(c.default || c))
export const NewUiBaseModal = () => import('../../components/new/ui/BaseModal.vue' /* webpackChunkName: "components/new-ui-base-modal" */).then(c => wrapFunctional(c.default || c))
export const NewUiBaseModalDialog = () => import('../../components/new/ui/BaseModalDialog.vue' /* webpackChunkName: "components/new-ui-base-modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const NewUiCarouselComp = () => import('../../components/new/ui/CarouselComp.vue' /* webpackChunkName: "components/new-ui-carousel-comp" */).then(c => wrapFunctional(c.default || c))
export const NewRegistrationButtons = () => import('../../components/new/registration/Buttons.vue' /* webpackChunkName: "components/new-registration-buttons" */).then(c => wrapFunctional(c.default || c))
export const NewRegistrationSteps = () => import('../../components/new/registration/Steps.vue' /* webpackChunkName: "components/new-registration-steps" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksPagination = () => import('../../components/new/blocks/Pagination/Pagination.vue' /* webpackChunkName: "components/new-blocks-pagination" */).then(c => wrapFunctional(c.default || c))
export const NewBlocksPaginationComp = () => import('../../components/new/blocks/Pagination/PaginationComp.vue' /* webpackChunkName: "components/new-blocks-pagination-comp" */).then(c => wrapFunctional(c.default || c))
export const NewFormsCvFormsCVItemForm = () => import('../../components/new/forms/cvForms/CVItemForm.vue' /* webpackChunkName: "components/new-forms-cv-forms-c-v-item-form" */).then(c => wrapFunctional(c.default || c))
export const NewFormsCvFormsCVItemFormsColumn = () => import('../../components/new/forms/cvForms/CVItemFormsColumn.vue' /* webpackChunkName: "components/new-forms-cv-forms-c-v-item-forms-column" */).then(c => wrapFunctional(c.default || c))
export const NewFormsRegistrationFormsFirstStep = () => import('../../components/new/forms/registrationForms/FirstStep.vue' /* webpackChunkName: "components/new-forms-registration-forms-first-step" */).then(c => wrapFunctional(c.default || c))
export const NewFormsRegistrationFormsSecondStep = () => import('../../components/new/forms/registrationForms/SecondStep.vue' /* webpackChunkName: "components/new-forms-registration-forms-second-step" */).then(c => wrapFunctional(c.default || c))
export const NewModalsCatalogFilters = () => import('../../components/new/modals/CatalogFilters/CatalogFilters.vue' /* webpackChunkName: "components/new-modals-catalog-filters" */).then(c => wrapFunctional(c.default || c))
export const NewModalsCatalogFiltersFilterEl = () => import('../../components/new/modals/CatalogFilters/FilterEl.vue' /* webpackChunkName: "components/new-modals-catalog-filters-filter-el" */).then(c => wrapFunctional(c.default || c))
export const NewModalsCatalogFiltersPaintingFilters = () => import('../../components/new/modals/CatalogFilters/PaintingFilters.vue' /* webpackChunkName: "components/new-modals-catalog-filters-painting-filters" */).then(c => wrapFunctional(c.default || c))
export const NewModalsProfileModalsEditConceptModal = () => import('../../components/new/modals/ProfileModals/EditConceptModal.vue' /* webpackChunkName: "components/new-modals-profile-modals-edit-concept-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsProfileModalsEditEducationsModal = () => import('../../components/new/modals/ProfileModals/EditEducationsModal.vue' /* webpackChunkName: "components/new-modals-profile-modals-edit-educations-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsProfileModalsEditPersonalModal = () => import('../../components/new/modals/ProfileModals/EditPersonalModal.vue' /* webpackChunkName: "components/new-modals-profile-modals-edit-personal-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsProfileModalsEditPublicationsModal = () => import('../../components/new/modals/ProfileModals/EditPublicationsModal.vue' /* webpackChunkName: "components/new-modals-profile-modals-edit-publications-modal" */).then(c => wrapFunctional(c.default || c))
export const NewModalsProfileModalsEditWithSearchModal = () => import('../../components/new/modals/ProfileModals/EditWithSearchModal.vue' /* webpackChunkName: "components/new-modals-profile-modals-edit-with-search-modal" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseCheckbox = () => import('../../components/new/ui/inputs/BaseCheckbox.vue' /* webpackChunkName: "components/new-ui-inputs-base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseDateTimePicker = () => import('../../components/new/ui/inputs/BaseDateTimePicker.vue' /* webpackChunkName: "components/new-ui-inputs-base-date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseFileInput = () => import('../../components/new/ui/inputs/BaseFileInput.vue' /* webpackChunkName: "components/new-ui-inputs-base-file-input" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseInput = () => import('../../components/new/ui/inputs/BaseInput.vue' /* webpackChunkName: "components/new-ui-inputs-base-input" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseInputDate = () => import('../../components/new/ui/inputs/BaseInputDate.vue' /* webpackChunkName: "components/new-ui-inputs-base-input-date" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseMaskedInput = () => import('../../components/new/ui/inputs/BaseMaskedInput.vue' /* webpackChunkName: "components/new-ui-inputs-base-masked-input" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseRadio = () => import('../../components/new/ui/inputs/BaseRadio.vue' /* webpackChunkName: "components/new-ui-inputs-base-radio" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseSelect = () => import('../../components/new/ui/inputs/BaseSelect.vue' /* webpackChunkName: "components/new-ui-inputs-base-select" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsBaseTimeInput = () => import('../../components/new/ui/inputs/BaseTimeInput.vue' /* webpackChunkName: "components/new-ui-inputs-base-time-input" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsMultiRangeSliderBaseRangeInput = () => import('../../components/new/ui/inputs/MultiRangeSlider/BaseRangeInput.vue' /* webpackChunkName: "components/new-ui-inputs-multi-range-slider-base-range-input" */).then(c => wrapFunctional(c.default || c))
export const NewUiInputsMultiRangeSlider = () => import('../../components/new/ui/inputs/MultiRangeSlider/MultiRangeSlider.vue' /* webpackChunkName: "components/new-ui-inputs-multi-range-slider" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
