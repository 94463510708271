
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Vue } from 'nuxt-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ToggleLang from '~/components/layout/menu/ToggleLang.vue'

@Component({
  components: { ToggleLang }
  })
export default class extends Vue {
  showLangList: boolean = false
}
